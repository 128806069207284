import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer>
    <section className="footersection">
      <div className="footer">
        <div>
          <div className="footerdiv">
            <h2>Navigation</h2>
            <ul>
              <li>
                <Link to="/#restaura-ratisbona-startseite">Start</Link>
              </li>
              <li>
                <Link to="/#philosophie">Philosophie</Link>
              </li>
              <li>
                <Link to="/#referenzen">Referenzen</Link>
              </li>
              <li>
                <Link to="/#leistungen">Leistungen</Link>
              </li>
              <li>
                <Link to="/#team">Team</Link>
              </li>
              <li>
                <Link to="/#kontakt">Kontakt</Link>
              </li>
              <li>
                <Link to="/impressum" className="link">
                  Impressum
                </Link>
              </li>
              <li>
                <Link to="/datenschutzerklärung" className="link">
                  Datenschutzerklärung
                </Link>
              </li>
            </ul>
          </div>
          <div className="footerdiv">
            <h2>Anschrift</h2>
            <ul>
              <li>Restaura Ratisbona</li>
              <li>Astrid Mendes da Vitoria</li>
              <li>Eilsbrunner Straße 17</li>
              <li>93161 Sinzing</li>
              <li>Deutschland</li>
            </ul>
          </div>
          <div className="footerdiv">
            <h2>Kontaktdaten</h2>
            <ul>
              <li>Astrid Mendes da Vitoria</li>
              <li>
                <a href="tel:+499416963520">Festnetz: +49 941 6963520</a>
              </li>
              <li>
                <a href="tel:+4917632756571">Mobil: +49 176 32756571</a>
              </li>
              <li>
                <a href="mailto:restaura.astridmendes@gmail.com">
                  E-Mail: restaura.astridmendes
                  <wbr />
                  @gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          <p>2020 © Astrid Mendes da Vitoria</p>
          <a href="https://agenturignis.de/">
            Konzeption & Design: Agentur Ignis
          </a>
        </div>
      </div>
    </section>
  </footer>
)

export default Footer
