import { Link } from "gatsby"
import React from "react"
import { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

import Logo from "./logo"

const Header = () => {
  const [navActive, setNavActive] = useState(false)

  const toggleNav = () => {
    setNavActive(!navActive)
  }

  const hideNav = () => {
    if (navActive) {
      setNavActive(false)
    }
  }

  return (
    <header>
      <section className="headersection">
        <Link to="/#restaura-ratisbona-startseite">
          <div className="logoDiv">
            <Logo />
          </div>
        </Link>
        <nav className="desktopNav">
          <ul>
            <li>
              <Link to="/#restaura-ratisbona-startseite">Start</Link>
            </li>
            <li>
              <Link to="/aktuelles">Aktuelles</Link>
            </li>
            <li>
              <Link to="/#philosophie">Philosophie</Link>
            </li>
            <li>
              <Link to="/#referenzen">Referenzen</Link>
            </li>
            <li>
              <Link to="/#leistungen">Leistungen</Link>
            </li>
            <li>
              <Link to="/#kontakt">Kontakt</Link>
            </li>
            <li>
              <Link to="/#team">Team</Link>
            </li>
          </ul>
        </nav>
        <nav className="mobileNav">
          <div className="fabars" onClick={toggleNav}>
            <FontAwesomeIcon icon={faBars} size="2x" />
          </div>
          {navActive && (
            <ul>
              <li>
                <Link onClick={hideNav} to="/#restaura-ratisbona-startseite">
                  Start
                </Link>
              </li>
              <li>
                <Link onClick={hideNav} to="/aktuelles">
                  Aktuelles
                </Link>
              </li>
              <li>
                <Link onClick={hideNav} to="/#philosophie">
                  Philosophie
                </Link>
              </li>
              <li>
                <Link onClick={hideNav} to="/#referenzen">
                  Referenzen
                </Link>
              </li>
              <li>
                <Link onClick={hideNav} to="/#leistungen">
                  Leistungen
                </Link>
              </li>
              <li>
                <Link onClick={hideNav} to="/#kontakt">
                  Kontakt
                </Link>
              </li>
              <li>
                <Link onClick={hideNav} to="/#team">
                  Team
                </Link>
              </li>
            </ul>
          )}
        </nav>
      </section>
    </header>
  )
}

export default Header
