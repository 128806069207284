import React from "react"

const Logo = () => {
  return (
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="100%" height="100%" viewBox="0 0 808.734 151.855" enableBackground="new 0 0 808.734 151.855"
    xmlSpace="preserve">
 <g>
   <path fill="#FFFFFF" d="M31.871,26.693v13.558l-1.49,0.595c-1.291-0.497-2.683-0.745-4.171-0.745
     c-4.271,0-8.194,2.733-11.771,8.194v7.3l0.895,22.797H0.434l0.745-21.455l-0.596-29.5l14.154-1.192l-0.149,10.281h0.894
     c3.575-6.653,8.095-10.032,13.558-10.131C29.733,26.495,30.678,26.595,31.871,26.693z"/>
   <path fill="#FFFFFF" d="M49.301,54.406c0.597,9.436,5.164,14.153,13.706,14.153c4.866,0,9.883-1.391,15.048-4.171l1.191,0.895
     l-1.938,10.874c-5.165,2.188-10.379,3.326-15.643,3.428c-8.246,0-14.653-2.386-19.219-7.153c-4.57-4.768-6.854-11.345-6.854-19.739
     c0-8.393,2.309-14.998,6.928-19.816c4.619-4.817,10.503-7.227,17.656-7.227c7.151,0,12.489,1.814,16.015,5.439
     c3.525,3.626,5.291,8.618,5.291,14.972c0,1.988-0.15,4.023-0.448,6.109L78.8,54.256L49.301,54.406z M60.624,35.335
     c-2.881,0-5.289,0.945-7.226,2.831c-1.937,1.888-3.203,4.568-3.8,8.045l18.773-0.596l0.298-1.192
     C68.569,38.365,65.886,35.335,60.624,35.335z"/>
   <path fill="#FFFFFF" d="M108,25.576c5.662-0.049,10.727,0.819,15.197,2.607l-1.937,10.875l-1.788,0.598
     c-4.47-2.881-8.543-4.322-12.217-4.322c-1.788,0-3.203,0.473-4.246,1.416c-1.044,0.945-1.564,2.11-1.564,3.501
     c0,1.392,0.694,2.583,2.086,3.576c1.39,0.995,3.575,2.086,6.555,3.277c2.979,1.192,5.439,2.361,7.375,3.5
     c1.938,1.144,3.599,2.683,4.991,4.619c1.39,1.938,2.086,4.396,2.086,7.375c0,2.98-0.745,5.763-2.235,8.346
     c-3.478,5.76-9.684,8.64-18.625,8.64c-5.364,0-10.927-1.145-16.687-3.428l1.789-11.621l1.341-0.894
     c2.283,1.988,4.866,3.551,7.748,4.693c2.879,1.143,5.363,1.711,7.449,1.711c2.087,0,3.674-0.495,4.768-1.488
     c1.093-0.992,1.639-2.209,1.639-3.65c0-1.438-0.695-2.682-2.086-3.724c-1.392-1.043-3.576-2.184-6.556-3.427
     c-2.979-1.242-5.415-2.408-7.301-3.502c-4.768-2.681-7.151-6.604-7.151-11.77c0-5.163,1.811-9.26,5.438-12.292
     C97.694,27.166,102.339,25.628,108,25.576z"/>
   <path fill="#FFFFFF" d="M155.527,67.366c2.284,0,4.715-0.595,7.301-1.788l1.192,1.192l-1.491,8.64
     c-3.675,1.989-7.5,3.177-11.472,3.577c-10.033-0.992-15.099-5.86-15.196-14.601L136.457,55V35.781h-7.002l-0.596-0.595l0.744-8.194
     h6.556v-8.64l13.557-4.321l1.043,0.895l-0.446,12.066h13.408l0.447,0.745l-0.596,8.044h-13.409l-0.596,25.03
     c0,2.384,0.421,4.073,1.266,5.065C151.676,66.87,153.241,67.366,155.527,67.366z"/>
   <path fill="#FFFFFF" d="M190.91,25.503c6.604,0,9.908,0,9.908,0s1.639,1.291,4.915,3.872c3.278,2.584,4.917,6.16,4.917,10.727
     c0,1.292-0.248,5.514-0.744,12.665l-0.447,12.217c0,1.292,0.149,2.161,0.447,2.607c0.298,0.447,0.895,0.669,1.788,0.669
     c0.894,0,1.937-0.147,3.129-0.444l1.192,0.892l-1.49,7.75c-2.384,1.489-5.065,2.434-8.045,2.83
     c-4.768-0.396-7.649-3.129-8.641-8.194h-0.746c-3.774,3.973-7.598,6.757-11.472,8.344c-5.363-0.101-9.586-1.538-12.663-4.32
     c-3.081-2.78-4.62-6.503-4.62-11.174c0-7.25,3.823-11.621,11.472-13.111l17.133-3.128v-4.619c0-4.866-2.287-7.302-6.853-7.302
     c-4.57,0-9.933,2.139-16.091,6.407l-1.192-0.595l-1.639-9.833C177.724,27.588,184.303,25.503,190.91,25.503z M185.025,57.683
     c-2.286,0.497-3.427,1.888-3.427,4.172c0,4.469,1.937,6.704,5.81,6.704c2.88,0,5.959-1.735,9.238-5.214l0.297-8.344L185.025,57.683
     z"/>
   <path fill="#FFFFFF" d="M270.84,68.259c0.895,0,1.937-0.147,3.13-0.444l1.042,0.892l-1.341,7.75
     c-2.383,1.489-5.116,2.434-8.193,2.83c-4.67-0.396-7.599-3.178-8.79-8.342h-1.192c-4.272,4.071-8.641,6.753-13.11,8.044
     c-5.563,0-9.833-1.266-12.812-3.799c-2.98-2.534-4.47-6.232-4.47-11.101l0.298-13.111l-0.447-23.54l14.302-1.192l-0.446,32.926
     c0,5.267,2.235,7.897,6.705,7.897c3.178,0,6.407-1.438,9.684-4.321V27.438l14.154-1.192l-0.894,38.737
     C268.458,67.169,269.25,68.259,270.84,68.259z"/>
   <path fill="#FFFFFF" d="M314.79,26.693v13.558l-1.49,0.595c-1.291-0.497-2.682-0.745-4.171-0.745
     c-4.271,0-8.194,2.733-11.771,8.194v7.3l0.895,22.797h-14.899l0.745-21.455l-0.596-29.5l14.154-1.192l-0.149,10.281h0.894
     c3.576-6.653,8.094-10.032,13.557-10.131C312.653,26.495,313.598,26.595,314.79,26.693z"/>
   <path fill="#FFFFFF" d="M341.085,25.503c6.604,0,9.908,0,9.908,0s1.639,1.291,4.915,3.872c3.278,2.584,4.917,6.16,4.917,10.727
     c0,1.292-0.249,5.514-0.745,12.665l-0.447,12.217c0,1.292,0.149,2.161,0.447,2.607c0.298,0.447,0.895,0.669,1.788,0.669
     c0.895,0,1.937-0.147,3.129-0.444l1.191,0.892l-1.491,7.75c-2.384,1.489-5.065,2.434-8.044,2.83
     c-4.768-0.396-7.649-3.129-8.641-8.194h-0.746c-3.774,3.973-7.598,6.757-11.472,8.344c-5.363-0.101-9.586-1.538-12.663-4.32
     c-3.08-2.78-4.62-6.503-4.62-11.174c0-7.25,3.823-11.621,11.473-13.111l17.133-3.128v-4.619c0-4.866-2.286-7.302-6.853-7.302
     c-4.57,0-9.933,2.139-16.091,6.407l-1.191-0.595l-1.639-9.833C327.9,27.588,334.479,25.503,341.085,25.503z M335.2,57.683
     c-2.287,0.497-3.427,1.888-3.427,4.172c0,4.469,1.937,6.704,5.811,6.704c2.88,0,5.959-1.735,9.237-5.214l0.297-8.344L335.2,57.683z
     "/>
   <path fill="#FFFFFF" d="M423.548,26.693v13.558l-1.489,0.595c-1.292-0.497-2.684-0.745-4.17-0.745
     c-4.273,0-8.196,2.733-11.771,8.194v7.3l0.895,22.797h-14.899l0.745-21.455l-0.596-29.5l14.155-1.192l-0.15,10.281h0.896
     c3.575-6.653,8.096-10.032,13.557-10.131C421.41,26.495,422.356,26.595,423.548,26.693z"/>
   <path fill="#FFFFFF" d="M449.843,25.503c6.604,0,9.908,0,9.908,0s1.64,1.291,4.916,3.872c3.278,2.584,4.917,6.16,4.917,10.727
     c0,1.292-0.249,5.514-0.745,12.665l-0.446,12.217c0,1.292,0.149,2.161,0.446,2.607c0.298,0.447,0.896,0.669,1.788,0.669
     c0.894,0,1.938-0.147,3.129-0.444l1.191,0.892l-1.489,7.75c-2.384,1.489-5.065,2.434-8.045,2.83
     c-4.769-0.396-7.65-3.129-8.642-8.194h-0.745c-3.774,3.973-7.599,6.757-11.473,8.344c-5.362-0.101-9.585-1.538-12.663-4.32
     c-3.079-2.78-4.618-6.503-4.618-11.174c0-7.25,3.823-11.621,11.472-13.111l17.135-3.128v-4.619c0-4.866-2.286-7.302-6.854-7.302
     s-9.93,2.139-16.09,6.407l-1.19-0.595l-1.64-9.833C436.657,27.588,443.236,25.503,449.843,25.503z M443.958,57.683
     c-2.285,0.497-3.428,1.888-3.428,4.172c0,4.469,1.938,6.704,5.811,6.704c2.88,0,5.959-1.735,9.236-5.214l0.299-8.344
     L443.958,57.683z"/>
   <path fill="#FFFFFF" d="M505.49,67.366c2.283,0,4.717-0.595,7.302-1.788l1.19,1.192l-1.49,8.64
     c-3.676,1.989-7.501,3.177-11.473,3.577c-10.031-0.992-15.099-5.86-15.195-14.601L486.42,55V35.781h-7.004l-0.595-0.595
     l0.744-8.194h6.556v-8.64l13.558-4.321l1.043,0.895l-0.445,12.066h13.408l0.447,0.745l-0.599,8.044h-13.406l-0.598,25.03
     c0,2.384,0.422,4.073,1.268,5.065C501.639,66.87,503.203,67.366,505.49,67.366z"/>
   <path fill="#FFFFFF" d="M529.625,1.067c2.383,0,4.221,0.67,5.513,2.012c1.289,1.34,1.937,3.229,1.937,5.662
     c0,2.436-0.694,4.396-2.086,5.885c-1.393,1.489-3.303,2.235-5.735,2.235c-2.437,0-4.32-0.67-5.662-2.011
     c-1.341-1.341-2.011-3.203-2.011-5.587s0.744-4.345,2.234-5.885C525.304,1.838,527.241,1.067,529.625,1.067z M536.627,78.393
     h-14.898l0.744-21.455l-0.596-29.5l14.898-1.192l-1.044,29.35L536.627,78.393z"/>
   <path fill="#FFFFFF" d="M566.421,25.576c5.66-0.049,10.728,0.819,15.197,2.607l-1.937,10.875l-1.787,0.598
     c-4.471-2.881-8.544-4.322-12.217-4.322c-1.789,0-3.204,0.473-4.247,1.416c-1.043,0.945-1.563,2.11-1.563,3.501
     c0,1.392,0.692,2.583,2.085,3.576c1.392,0.995,3.575,2.086,6.557,3.277c2.979,1.192,5.438,2.361,7.376,3.5
     c1.936,1.144,3.598,2.683,4.99,4.619c1.389,1.938,2.086,4.396,2.086,7.375c0,2.98-0.745,5.763-2.235,8.346
     c-3.478,5.76-9.685,8.64-18.623,8.64c-5.363,0-10.929-1.145-16.687-3.428l1.786-11.621l1.341-0.894
     c2.285,1.988,4.867,3.551,7.748,4.693c2.88,1.143,5.363,1.711,7.449,1.711c2.087,0,3.675-0.495,4.769-1.488
     c1.092-0.992,1.639-2.209,1.639-3.65c0-1.438-0.696-2.682-2.085-3.724c-1.393-1.043-3.576-2.184-6.558-3.427
     c-2.979-1.242-5.415-2.408-7.299-3.502c-4.769-2.681-7.153-6.604-7.153-11.77c0-5.163,1.812-9.26,5.438-12.292
     C556.116,27.166,560.761,25.628,566.421,25.576z"/>
   <path fill="#FFFFFF" d="M635.775,32.429c3.524,4.421,5.289,10.902,5.289,19.443c0,8.543-2.311,15.296-6.929,20.262
     c-4.619,4.968-10.901,7.451-18.846,7.451c-3.776,0-7.948-0.896-12.515-2.684l-7.896,2.831l-1.788-1.488l0.896-21.157L593.24,1.217
     l14.748-1.192l-0.298,33.82h0.446c4.072-3.773,8.243-6.454,12.515-8.044C627.208,25.8,632.249,28.01,635.775,32.429z M615.141,69.9
     c7.945-0.099,11.92-5.487,11.92-16.165s-3.33-15.966-9.982-15.867c-3.08,0-6.257,1.489-9.535,4.469l-0.299,12.813l0.299,12.664
     C610.32,69.107,612.854,69.801,615.141,69.9z"/>
   <path fill="#FFFFFF" d="M671.977,25.503c7.896,0,13.831,2.234,17.805,6.703c3.971,4.47,5.959,11.077,5.959,19.815
     c0,8.742-2.16,15.521-6.481,20.336c-4.319,4.819-10.38,7.227-18.177,7.227c-7.799,0-13.706-2.259-17.729-6.778
     c-4.021-4.521-6.034-11.149-6.034-19.891c0-8.739,2.137-15.495,6.407-20.263C657.994,27.885,664.08,25.503,671.977,25.503z
      M671.455,35.483c-3.576,0-6.16,1.218-7.747,3.65c-1.59,2.435-2.385,6.681-2.385,12.738c0,6.059,0.795,10.503,2.385,13.334
     c1.587,2.831,4.22,4.246,7.896,4.246c3.674,0,6.307-1.14,7.896-3.425c1.588-2.285,2.385-6.455,2.385-12.516
     c0-6.057-0.846-10.578-2.533-13.557C677.661,36.974,675.031,35.483,671.455,35.483z"/>
   <path fill="#FFFFFF" d="M737.604,46.062c0-5.461-2.335-8.193-7.002-8.193c-3.378,0-6.952,1.59-10.725,4.767v12.961l0.745,22.796
     H705.72l0.744-21.454l-0.596-29.5l14.156-1.191v7.598h0.745c4.269-3.773,8.738-6.454,13.408-8.044c5.66,0,9.954,1.292,12.886,3.874
     c2.931,2.583,4.346,6.257,4.247,11.024l-0.298,14.898l0.596,22.796H736.71L737.604,46.062z"/>
   <path fill="#FFFFFF" d="M783.566,25.503c6.603,0,9.906,0,9.906,0s1.638,1.291,4.918,3.872c3.275,2.584,4.916,6.16,4.916,10.727
     c0,1.292-0.251,5.514-0.744,12.665l-0.449,12.217c0,1.292,0.148,2.161,0.449,2.607c0.299,0.447,0.895,0.669,1.787,0.669
     c0.895,0,1.938-0.147,3.128-0.444l1.19,0.892l-1.489,7.75c-2.382,1.489-5.065,2.434-8.044,2.83
     c-4.769-0.396-7.649-3.129-8.641-8.194h-0.745c-3.776,3.973-7.602,6.757-11.473,8.344c-5.364-0.101-9.587-1.538-12.664-4.32
     c-3.08-2.78-4.62-6.503-4.62-11.174c0-7.25,3.825-11.621,11.474-13.111l17.135-3.128v-4.619c0-4.866-2.288-7.302-6.853-7.302
     c-4.572,0-9.936,2.139-16.093,6.407l-1.19-0.595l-1.64-9.833C770.381,27.588,776.96,25.503,783.566,25.503z M777.682,57.683
     c-2.289,0.497-3.431,1.888-3.431,4.172c0,4.469,1.939,6.704,5.812,6.704c2.88,0,5.96-1.735,9.239-5.214l0.298-8.344L777.682,57.683
     z"/>
 </g>
 <g>
   <path fill="#FFFFFF" d="M15.458,137.908H0.436v-3.018l4.893-1.109v-33.285L0.436,99.33v-3.018h15.021v3.018l-4.894,1.166v33.285
     l4.894,1.109V137.908z"/>
   <path fill="#FFFFFF" d="M44.079,137.908v-19.973c0-2.541-0.563-4.434-1.692-5.676s-2.898-1.862-5.306-1.862
     c-3.168,0-5.486,0.88-6.958,2.645c-1.469,1.764-2.204,4.676-2.204,8.734v16.133h-5.121V93.639h5.121v12.973
     c0,1.708-0.095,3.168-0.285,4.382h0.341c0.929-1.519,2.248-2.703,3.955-3.557c1.707-0.854,3.632-1.28,5.776-1.28
     c3.793,0,6.647,0.908,8.563,2.718c1.915,1.812,2.874,4.709,2.874,8.693v20.342L44.079,137.908L44.079,137.908z"/>
   <path fill="#FFFFFF" d="M73.382,106.1c1.384,0,2.607,0.113,3.67,0.342l-0.626,4.722c-1.156-0.267-2.285-0.396-3.385-0.396
     c-1.649,0-3.182,0.453-4.595,1.364c-1.413,0.909-2.518,2.168-3.314,3.771c-0.796,1.603-1.195,3.381-1.195,5.333v16.674h-5.121
     V106.67h4.211l0.569,5.717h0.228c1.196-2.065,2.608-3.631,4.24-4.694C69.693,106.63,71.466,106.1,73.382,106.1z"/>
   <path fill="#FFFFFF" d="M111.903,137.908h-5.264v-36.986H93.639v-4.61h31.181v4.61h-12.916V137.908L111.903,137.908z"/>
   <path fill="#FFFFFF" d="M141.72,138.476c-4.685,0-8.36-1.417-11.024-4.252c-2.665-2.837-3.998-6.74-3.998-11.709
     c0-5.007,1.243-8.993,3.728-11.961c2.483-2.97,5.842-4.453,10.072-4.453c3.926,0,7.055,1.265,9.388,3.798
     c2.333,2.534,3.5,5.962,3.5,10.285v3.103H131.99c0.096,3.547,0.996,6.238,2.702,8.077c1.708,1.841,4.126,2.761,7.256,2.761
     c1.668,0,3.253-0.146,4.751-0.439c1.499-0.295,3.252-0.869,5.263-1.722v4.494c-1.727,0.739-3.348,1.262-4.865,1.566
     C145.579,138.323,143.786,138.476,141.72,138.476z M140.439,110.283c-2.445,0-4.381,0.785-5.803,2.358
     c-1.422,1.576-2.267,3.766-2.533,6.574h15.904c-0.039-2.923-0.711-5.141-2.021-6.656
     C144.679,111.04,142.83,110.283,140.439,110.283z"/>
   <path fill="#FFFFFF" d="M180.583,137.908l-0.995-4.439h-0.228c-1.555,1.952-3.105,3.275-4.651,3.969
     c-1.545,0.689-3.495,1.038-5.846,1.038c-3.072,0-5.482-0.806-7.226-2.418c-1.745-1.612-2.617-3.889-2.617-6.83
     c0-6.332,4.997-9.65,14.994-9.954l5.292-0.199v-1.85c0-2.371-0.512-4.121-1.536-5.249c-1.024-1.13-2.666-1.691-4.922-1.691
     c-1.65,0-3.21,0.246-4.68,0.737c-1.469,0.494-2.849,1.045-4.14,1.649l-1.564-3.839c1.573-0.836,3.291-1.49,5.149-1.963
     c1.858-0.476,3.698-0.713,5.519-0.713c3.774,0,6.581,0.836,8.421,2.505c1.839,1.669,2.759,4.322,2.759,7.964v21.283H180.583z
      M170.027,134.352c2.864,0,5.117-0.773,6.757-2.319c1.641-1.546,2.461-3.74,2.461-6.587v-2.815l-4.608,0.2
     c-3.585,0.132-6.198,0.701-7.839,1.706c-1.64,1.005-2.46,2.591-2.46,4.751c0,1.631,0.498,2.883,1.493,3.755
     C166.827,133.914,168.225,134.352,170.027,134.352z"/>
   <path fill="#FFFFFF" d="M233.728,137.908v-20.114c0-4.933-2.115-7.397-6.345-7.397c-2.901,0-5.035,0.842-6.402,2.529
     c-1.366,1.688-2.048,4.27-2.048,7.74v17.242h-5.092v-20.114c0-2.467-0.517-4.315-1.551-5.549c-1.034-1.232-2.65-1.849-4.85-1.849
     c-2.883,0-4.994,0.88-6.331,2.645c-1.337,1.764-2.005,4.657-2.005,8.676v16.191h-5.122v-31.239h4.126l0.768,4.266h0.285
     c0.892-1.518,2.148-2.7,3.77-3.557c1.622-0.852,3.438-1.278,5.447-1.278c4.895,0,8.081,1.745,9.56,5.235h0.284
     c1.005-1.669,2.376-2.959,4.111-3.869s3.704-1.365,5.904-1.365c3.528,0,6.169,0.911,7.924,2.732
     c1.754,1.821,2.631,4.732,2.631,8.735v20.342h-5.065V137.908z"/>
   <path fill="#FFFFFF" d="M277.997,110.654h-7.881v27.255h-5.12v-27.255h-5.463v-2.392l5.463-1.765v-1.792
     c0-3.792,0.829-6.639,2.489-8.534c1.659-1.898,4.215-2.847,7.666-2.847c1.992,0,4.012,0.352,6.061,1.053l-1.366,4.01
     c-1.764-0.566-3.31-0.851-4.637-0.851c-1.727,0-3.006,0.567-3.841,1.707c-0.834,1.137-1.252,2.938-1.252,5.404v2.021h7.881
     L277.997,110.654L277.997,110.654z"/>
   <path fill="#FFFFFF" d="M288.495,106.669v20.114c0,2.522,0.564,4.395,1.692,5.618c1.128,1.223,2.878,1.834,5.248,1.834
     c3.206,0,5.539-0.891,7-2.673c1.46-1.784,2.19-4.656,2.19-8.621v-16.272h5.149v31.239h-4.211l-0.739-4.127h-0.257
     c-0.93,1.499-2.248,2.655-3.955,3.472c-1.706,0.815-3.688,1.224-5.945,1.224c-3.756,0-6.586-0.9-8.492-2.701
     c-1.908-1.802-2.86-4.687-2.86-8.648V106.67L288.495,106.669L288.495,106.669z M288.324,98.219c0-1.005,0.265-1.731,0.796-2.175
     c0.531-0.446,1.176-0.67,1.934-0.67c0.74,0,1.385,0.224,1.936,0.67c0.55,0.443,0.825,1.17,0.825,2.175
     c0,1.006-0.275,1.744-0.825,2.218c-0.551,0.478-1.195,0.713-1.936,0.713c-0.758,0-1.403-0.235-1.934-0.713
     C288.588,99.963,288.324,99.225,288.324,98.219z M299.276,98.219c0-1.005,0.265-1.731,0.796-2.175
     c0.531-0.446,1.157-0.67,1.878-0.67c0.74,0,1.384,0.224,1.935,0.67c0.55,0.443,0.825,1.17,0.825,2.175
     c0,1.006-0.275,1.744-0.825,2.218c-0.55,0.478-1.195,0.713-1.935,0.713c-0.722,0-1.348-0.235-1.878-0.713
     C299.542,99.963,299.276,99.225,299.276,98.219z"/>
   <path fill="#FFFFFF" d="M334.242,106.1c1.384,0,2.608,0.113,3.67,0.342l-0.626,4.722c-1.157-0.267-2.286-0.396-3.385-0.396
     c-1.65,0-3.182,0.453-4.595,1.364c-1.413,0.909-2.518,2.168-3.314,3.771c-0.797,1.603-1.195,3.381-1.195,5.333v16.674h-5.121
     V106.67h4.211l0.569,5.717h0.228c1.194-2.065,2.608-3.631,4.239-4.694C330.553,106.63,332.326,106.1,334.242,106.1z"/>
   <path fill="#FFFFFF" d="M364.827,120.724v17.185h-5.235V96.312h11.465c5.159,0,8.976,0.981,11.452,2.945
     c2.476,1.963,3.713,4.917,3.713,8.862c0,5.519-2.779,9.237-8.336,11.153l11.409,18.636h-6.116l-10.071-17.185H364.827z
      M364.827,116.283h6.23c3.376,0,5.842-0.662,7.397-1.989c1.555-1.329,2.333-3.301,2.333-5.918s-0.787-4.528-2.361-5.733
     c-1.573-1.204-4.125-1.806-7.652-1.806h-5.947V116.283z"/>
   <path fill="#FFFFFF" d="M408.412,138.476c-4.686,0-8.359-1.417-11.025-4.252c-2.665-2.837-3.997-6.74-3.997-11.709
     c0-5.007,1.242-8.993,3.727-11.961c2.484-2.97,5.842-4.453,10.073-4.453c3.926,0,7.055,1.265,9.388,3.798
     c2.331,2.534,3.5,5.962,3.5,10.285v3.103h-21.396c0.095,3.547,0.996,6.238,2.702,8.077c1.708,1.841,4.127,2.761,7.255,2.761
     c1.668,0,3.253-0.146,4.751-0.439c1.499-0.295,3.254-0.869,5.264-1.722v4.494c-1.727,0.739-3.349,1.262-4.864,1.566
     C412.271,138.323,410.479,138.476,408.412,138.476z M407.134,110.283c-2.447,0-4.383,0.785-5.805,2.358
     c-1.422,1.576-2.267,3.766-2.533,6.574h15.905c-0.039-2.923-0.713-5.141-2.021-6.656
     C411.371,111.04,409.521,110.283,407.134,110.283z"/>
   <path fill="#FFFFFF" d="M448.327,129.286c0,2.921-1.091,5.183-3.272,6.786c-2.181,1.603-5.242,2.402-9.189,2.402
     c-4.116,0-7.387-0.653-9.816-1.965v-4.664c3.435,1.669,6.742,2.505,9.931,2.505c2.578,0,4.456-0.417,5.634-1.254
     c1.175-0.832,1.764-1.953,1.764-3.356c0-1.232-0.565-2.275-1.693-3.13c-1.13-0.854-3.134-1.83-6.018-2.932
     c-2.939-1.137-5.007-2.106-6.2-2.915c-1.195-0.807-2.073-1.711-2.632-2.716c-0.562-1.007-0.842-2.229-0.842-3.67
     c0-2.562,1.044-4.582,3.131-6.061c2.087-1.48,4.95-2.22,8.592-2.22c3.547,0,6.866,0.702,9.958,2.105l-1.736,4.069
     c-3.167-1.329-6.021-1.99-8.563-1.99c-2.124,0-3.736,0.337-4.836,1.008c-1.101,0.675-1.648,1.598-1.648,2.776
     c0,1.137,0.472,2.082,1.422,2.828c0.946,0.751,3.157,1.788,6.627,3.115c2.6,0.969,4.521,1.868,5.762,2.704
     c1.243,0.835,2.158,1.772,2.745,2.815C448.034,126.574,448.327,127.825,448.327,129.286z"/>
   <path fill="#FFFFFF" d="M466.337,134.295c0.663,0,1.441-0.067,2.332-0.199c0.892-0.134,1.563-0.285,2.021-0.456v3.927
     c-0.475,0.209-1.21,0.413-2.205,0.608c-0.995,0.2-2.006,0.301-3.028,0.301c-6.108,0-9.161-3.217-9.161-9.645v-18.178h-4.409v-2.448
     l4.467-2.049l2.049-6.655h3.044v7.169h9.019v3.984h-9.019v18.036c0,1.803,0.431,3.187,1.294,4.153
     C463.602,133.811,464.799,134.295,466.337,134.295z"/>
   <path fill="#FFFFFF" d="M496.209,137.908l-0.996-4.439h-0.229c-1.557,1.952-3.106,3.275-4.652,3.969
     c-1.546,0.689-3.494,1.038-5.847,1.038c-3.071,0-5.481-0.806-7.227-2.418s-2.616-3.889-2.616-6.83c0-6.332,4.998-9.65,14.993-9.954
     l5.291-0.199v-1.85c0-2.371-0.511-4.121-1.536-5.249c-1.022-1.13-2.662-1.691-4.922-1.691c-1.648,0-3.209,0.246-4.68,0.737
     c-1.472,0.494-2.851,1.045-4.139,1.649l-1.565-3.839c1.574-0.836,3.291-1.49,5.15-1.963c1.858-0.476,3.698-0.713,5.52-0.713
     c3.775,0,6.58,0.836,8.421,2.505c1.839,1.669,2.759,4.322,2.759,7.964v21.283H496.209z M485.654,134.352
     c2.862,0,5.115-0.773,6.756-2.319c1.641-1.545,2.461-3.74,2.461-6.587v-2.815l-4.61,0.2c-3.584,0.132-6.198,0.701-7.839,1.706
     c-1.639,1.005-2.457,2.591-2.457,4.751c0,1.631,0.496,2.883,1.491,3.755C482.453,133.914,483.852,134.352,485.654,134.352z"/>
   <path fill="#FFFFFF" d="M514.446,106.669v20.114c0,2.522,0.561,4.395,1.69,5.618c1.129,1.223,2.878,1.834,5.25,1.834
     c3.204,0,5.537-0.891,6.999-2.673c1.46-1.784,2.19-4.656,2.19-8.621v-16.272h5.149v31.239h-4.211l-0.738-4.127h-0.257
     c-0.929,1.499-2.248,2.655-3.955,3.472c-1.706,0.817-3.688,1.224-5.945,1.224c-3.756,0-6.586-0.9-8.493-2.701
     c-1.905-1.802-2.858-4.687-2.858-8.648V106.67L514.446,106.669L514.446,106.669z"/>
   <path fill="#FFFFFF" d="M560.194,106.1c1.384,0,2.606,0.113,3.669,0.342l-0.625,4.722c-1.156-0.267-2.287-0.396-3.386-0.396
     c-1.649,0-3.185,0.453-4.595,1.364c-1.413,0.909-2.52,2.168-3.316,3.771c-0.795,1.603-1.193,3.381-1.193,5.333v16.674h-5.12V106.67
     h4.209l0.568,5.717h0.229c1.193-2.065,2.607-3.631,4.236-4.694C556.505,106.63,558.278,106.1,560.194,106.1z"/>
   <path fill="#FFFFFF" d="M569.299,98.219c0-1.138,0.286-1.965,0.866-2.475c0.579-0.514,1.294-0.771,2.148-0.771
     c0.796,0,1.493,0.257,2.091,0.771c0.598,0.51,0.896,1.337,0.896,2.475c0,1.119-0.298,1.943-0.896,2.476
     c-0.598,0.53-1.295,0.797-2.091,0.797c-0.854,0-1.568-0.267-2.148-0.797C569.585,100.162,569.299,99.338,569.299,98.219z
      M574.816,137.908h-5.12v-31.239h5.12V137.908z"/>
   <path fill="#FFFFFF" d="M598.005,138.476c-4.687,0-8.362-1.417-11.025-4.252c-2.667-2.837-3.997-6.74-3.997-11.709
     c0-5.007,1.24-8.993,3.727-11.961c2.485-2.97,5.842-4.453,10.072-4.453c3.926,0,7.057,1.265,9.387,3.798
     c2.334,2.534,3.499,5.962,3.499,10.285v3.103h-21.394c0.095,3.547,0.996,6.238,2.703,8.077c1.707,1.841,4.123,2.761,7.255,2.761
     c1.669,0,3.252-0.146,4.749-0.439c1.499-0.295,3.254-0.869,5.265-1.722v4.494c-1.727,0.739-3.348,1.262-4.863,1.566
     C601.863,138.323,600.071,138.476,598.005,138.476z M596.724,110.283c-2.446,0-4.381,0.785-5.804,2.358
     c-1.423,1.576-2.267,3.766-2.533,6.574h15.904c-0.038-2.923-0.709-5.141-2.021-6.656
     C600.963,111.04,599.113,110.283,596.724,110.283z"/>
   <path fill="#FFFFFF" d="M632.144,106.1c1.384,0,2.608,0.113,3.669,0.342l-0.625,4.722c-1.157-0.267-2.285-0.396-3.386-0.396
     c-1.65,0-3.183,0.453-4.594,1.364c-1.413,0.909-2.52,2.168-3.315,3.771c-0.797,1.603-1.195,3.381-1.195,5.333v16.674h-5.12V106.67
     h4.211l0.567,5.717h0.228c1.196-2.065,2.608-3.631,4.239-4.694C628.455,106.63,630.227,106.1,632.144,106.1z"/>
   <path fill="#FFFFFF" d="M646.482,106.669v20.114c0,2.522,0.563,4.395,1.691,5.618c1.131,1.223,2.879,1.834,5.25,1.834
     c3.206,0,5.538-0.891,7-2.673c1.46-1.784,2.188-4.656,2.188-8.621v-16.272h5.15v31.239h-4.209l-0.74-4.127h-0.256
     c-0.93,1.499-2.247,2.655-3.954,3.472c-1.708,0.817-3.691,1.224-5.946,1.224c-3.755,0-6.587-0.9-8.493-2.701
     c-1.907-1.802-2.86-4.687-2.86-8.648V106.67L646.482,106.669L646.482,106.669z"/>
   <path fill="#FFFFFF" d="M698.943,137.908v-19.973c0-2.541-0.562-4.434-1.691-5.676c-1.128-1.242-2.897-1.862-5.305-1.862
     c-3.205,0-5.535,0.886-6.985,2.658c-1.45,1.773-2.178,4.661-2.178,8.661v16.191h-5.119v-31.239h4.124l0.769,4.266h0.284
     c0.969-1.535,2.324-2.726,4.068-3.57c1.745-0.843,3.672-1.265,5.778-1.265c3.829,0,6.674,0.931,8.533,2.789
     c1.86,1.858,2.79,4.749,2.79,8.678v20.342H698.943z"/>
   <path fill="#FFFFFF" d="M734.535,137.623l0.173-3.842h-0.229c-2.107,3.13-5.33,4.695-9.674,4.695c-4.041,0-7.194-1.408-9.46-4.226
     c-2.266-2.816-3.399-6.784-3.399-11.906c0-5.026,1.143-8.99,3.428-11.893c2.285-2.901,5.41-4.353,9.374-4.353
     c4.231,0,7.492,1.574,9.787,4.722h0.314l0.682-4.151h4.069v31.748c0,4.479-1.135,7.844-3.401,10.102
     c-2.268,2.257-5.79,3.386-10.569,3.386c-4.552,0-8.299-0.665-11.238-1.991v-4.723c3.034,1.632,6.874,2.445,11.522,2.445
     c2.655,0,4.756-0.776,6.302-2.332c1.545-1.557,2.317-3.708,2.317-6.459v-1.223H734.535z M725.574,134.295
     c3.148,0,5.439-0.852,6.87-2.549c1.431-1.695,2.15-4.425,2.15-8.18v-1.223c0-4.174-0.724-7.208-2.163-9.104
     c-1.441-1.898-3.766-2.845-6.97-2.845c-2.618,0-4.644,1.037-6.075,3.114c-1.432,2.075-2.148,5.04-2.148,8.889
     c0,3.87,0.696,6.819,2.092,8.849C720.723,133.28,722.805,134.295,725.574,134.295z"/>
   <path fill="#FFFFFF" d="M762.815,138.476c-4.685,0-8.359-1.417-11.025-4.252c-2.664-2.837-3.995-6.74-3.995-11.709
     c0-5.007,1.241-8.993,3.726-11.961c2.485-2.97,5.842-4.453,10.072-4.453c3.927,0,7.056,1.265,9.389,3.798
     c2.331,2.534,3.499,5.962,3.499,10.285v3.103h-21.395c0.094,3.547,0.994,6.238,2.7,8.077c1.708,1.841,4.126,2.761,7.257,2.761
     c1.668,0,3.252-0.146,4.75-0.439c1.499-0.295,3.253-0.869,5.266-1.722v4.494c-1.728,0.739-3.351,1.262-4.867,1.566
     C766.675,138.323,764.883,138.476,762.815,138.476z M761.535,110.283c-2.447,0-4.383,0.785-5.805,2.358
     c-1.422,1.576-2.267,3.766-2.533,6.574h15.906c-0.038-2.923-0.711-5.141-2.021-6.656
     C765.775,111.04,763.926,110.283,761.535,110.283z"/>
   <path fill="#FFFFFF" d="M803.671,137.908v-19.973c0-2.541-0.565-4.434-1.692-5.676c-1.13-1.242-2.899-1.862-5.307-1.862
     c-3.206,0-5.533,0.886-6.984,2.658c-1.452,1.773-2.177,4.661-2.177,8.661v16.191h-5.122v-31.239h4.125l0.77,4.266h0.285
     c0.967-1.535,2.322-2.726,4.066-3.57c1.746-0.842,3.671-1.265,5.776-1.265c3.832,0,6.678,0.931,8.536,2.789
     c1.857,1.858,2.787,4.749,2.787,8.678v20.342H803.671z"/>
 </g>
 </svg>
 
  )
}

export default Logo
